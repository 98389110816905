import _isArray from "lodash/isArray";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import {
  type ContractHit,
  type ContractResult,
  MatchLevelEnum,
  type MatchResult,
  type RelevantContract,
  type RelevantContractSupplier,
  SearchTypeEnum,
  type SupplierContractHit,
  type SupplierHit,
  type SupplierSearchResponse,
} from "../../generated";
import { getMatchTier } from "../../shared/SearchPage/utils";
import { getSentenceCase } from "../../utils";
import { textColorClass } from "../../utils/colors";
import { type SearchFilter, contractMatchTypes } from "../../utils/enums";
import type { ContractSearchParams, SearchParams } from "./types";

const snippetFields = [
  "summary",
  "contractContent",
  "otherDocsContent",
  "amendmentsContent",
  "pricingContent",
  "pricingDetails",
  "pricingSummary",
];

export function getNumberSupplierHits(
  responseData: SupplierSearchResponse | null
) {
  return responseData?.supplierData?.suppliers?.length || 0;
}

export function getCountsForTracking(contract_results?: ContractResult[]) {
  let strongMatchesCount = 0;
  let possibleMatchesCount = 0;
  let scopeMatchesCount = 0;
  let supplierNameMatchesCount = 0;
  let semanticMatchesCount = 0;
  let competitorNameMatchesCount = 0;

  contract_results?.forEach((r) => {
    const matchTier = getMatchTier(r);
    switch (matchTier) {
      case contractMatchTypes.OCR:
        possibleMatchesCount += 1;
        break;
      case contractMatchTypes.SCOPE:
        scopeMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.EXACT_SUPPLIER:
        supplierNameMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.COMPETITOR_MATCH:
        competitorNameMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      case contractMatchTypes.SEMANTIC:
        semanticMatchesCount += 1;
        strongMatchesCount += 1;
        break;
      default:
        strongMatchesCount += 1;
    }
  });

  return {
    strongMatchesCount,
    possibleMatchesCount,
    scopeMatchesCount,
    supplierNameMatchesCount,
    semanticMatchesCount,
    competitorNameMatchesCount,
  };
}

export type SearchParamsValidation =
  | { valid: true }
  | {
      valid: false;
      errorMessage: string;
    };

export function validateSearchParams(
  contractSearchParams: ContractSearchParams
): SearchParamsValidation {
  if (!contractSearchParams.query && !contractSearchParams.landingPageSlug) {
    return { valid: false, errorMessage: "Please enter a search" };
  }

  if (contractSearchParams.query.length > 512) {
    return {
      valid: false,
      errorMessage: "Your search is too long, please shorten it and try again",
    };
  }
  return { valid: true };
}

export function formatSearchPageParams(
  searchParams: SearchParams
): ContractSearchParams {
  let parsedPageNumber = 0;
  if (searchParams.page) {
    parsedPageNumber = Number.parseInt(searchParams.page);
    if (Number.isNaN(parsedPageNumber)) {
      parsedPageNumber = 0;
    }
  }

  let parsedFilters: SearchFilter[] = [];
  if (searchParams.filters) {
    parsedFilters = `${searchParams.filters}`
      .split(";")
      .filter((f) => !!f) as SearchFilter[];
  }

  const csp: ContractSearchParams = {
    query: searchParams.query,
    zip: searchParams.zip || "",
    embedSourceEntityId: searchParams.embedSourceEntityId,
    landingPageSlug: searchParams.landingPageSlug,
    page: parsedPageNumber,
    searchSource: searchParams["analytics-search-source"],
    filters: parsedFilters,
    userSelectedFromDefault:
      searchParams["user-selected-from-default"] === "true",
    collapseBySupplier: searchParams.collapseBySupplier,
    searchType: searchParams.collapseBySupplier
      ? SearchTypeEnum.SUPPLIER
      : SearchTypeEnum.CONTRACT_SOLICITATION,
  };

  return csp;
}

export function matchResultComparator(a: MatchResult, b: MatchResult): number {
  if (a.queryCompleteness !== undefined && b.queryCompleteness !== undefined) {
    const queryCompleteness = b.queryCompleteness - a.queryCompleteness;
    if (queryCompleteness) return queryCompleteness;
  }
  if (a.fieldCompleteness !== undefined && b.fieldCompleteness !== undefined) {
    const fieldCompleteness = b.fieldCompleteness - a.fieldCompleteness;
    if (fieldCompleteness) return fieldCompleteness;
  }
  // Tiebreak ensuring semantic matches come first
  if (a.semanticMatch) return -1;
  if (b.semanticMatch) return 1;
  return matchTiers[b.matchLevel] - matchTiers[a.matchLevel];
}

export function matchesFromList({
  matchResults,
  isOCR,
  key,
}: {
  matchResults: MatchResult[];
  isOCR?: boolean;
  key: keyof typeof matchCopyByKey;
}) {
  const copyText = isOCR ? matchCopyOCR : matchCopyByKey[key];

  return matchResults
    .filter((m) => m.matchLevel !== MatchLevelEnum.NONE)
    .sort(matchResultComparator)
    .map((match) => ({
      ...match,
      copyText,
      isOCR,
      matchKind: key,
    }));
}

const matchCopyOCR = "Found in contract documents: ";
const matchCopyByKey = {
  contractOfferings: "Confirmed in contract scope: ",
  autoExtractedOfferingsList: "Confirmed in contract document: ",
  contractBrands: "From brand on contract: ",
  contractNumber: "From contract number: ",
  semanticOffering: "Identified by AI in contract: ",
} as const;

export function matchesForKey(
  hitSection: ContractHit["SnippetResult"] | ContractHit["HighlightResult"],
  key: string,
  isOCR = false
) {
  if (!hitSection || !(key in hitSection)) {
    return [];
  }

  const matchResults = hitSection[key];
  return matchesFromList({
    matchResults: _isArray(matchResults) ? matchResults : [matchResults],
    isOCR,
    key: key as keyof typeof matchCopyByKey,
  });
}

export function matchesForContractHit(hit: ContractHit | RelevantContract) {
  const result = hit.HighlightResult;

  const snippetMatches = snippetFields.flatMap((key) =>
    matchesForKey(hit.SnippetResult, key, true)
  );
  const contractNumberMatches = matchesForKey(result, "contractNumber");
  const offeringMatches = matchesFromList({
    matchResults: hit.offerings,
    key: "contractOfferings",
  });

  const semanticMatches = matchesForKey(result, "semanticOffering");
  const autoExtractedOfferingsMatches = matchesForKey(
    result,
    "autoExtractedOfferingsList"
  ).map((match) => ({ ...match, isAutoExtracted: true }));
  const contractBrandMatches = matchesForKey(result, "contractBrands");
  return [
    ...offeringMatches,
    ...contractNumberMatches,
    ...contractBrandMatches,
    ...snippetMatches,
    ...autoExtractedOfferingsMatches,
    ...semanticMatches,
  ];
}

export const matchTiers: Record<MatchLevelEnum, number> = {
  full: 3,
  partial: 2,
  semantic: 1,
  none: 0,
};

const DEFAULT_CLASS = `${textColorClass.neutral.bolder.enabled} font-normal`;
const DEFAULT_HIGHLIGHT_CLASS = `${textColorClass.neutral.bolder.enabled} font-semibold bg-cp-persimmon-100`;

// Split and style text with highlighted segments based on bolding returned from search.
export function styleSearchResponseText(
  value: string,
  options?: {
    tag?: string; // This is "em" (<em></em>) for some cases, but may be more complex in other cases.
    className?: string;
    highlightClassName?: string;
    sentenceCase?: boolean;
  }
) {
  const stringBreakpoints: [string, boolean][] = []; // List of tuples (substring, shouldBeStyled)
  let pointer = 0; // Furthest in the string we've looked.

  // Essentially split up the string based on occurrences of <tag> and </tag>
  // And mark whether each "chunk" of the string should be styled or not.
  // We'll remove the highlighting markers before returning the actual components.
  const tag = options?.tag || "em";
  const className = options?.className || DEFAULT_CLASS;
  const highlightClassName =
    options?.highlightClassName || DEFAULT_HIGHLIGHT_CLASS;
  const openTag = `<${tag}>`;
  const closeTag = `</${tag}>`;
  while (pointer < value.length) {
    const startIndex = value.indexOf(openTag, pointer);
    const endIndex = value.indexOf(closeTag, pointer + 1);

    if (startIndex === -1 || endIndex === -1) {
      stringBreakpoints.push([value.substring(pointer), false]);
      break;
    }

    if (pointer < startIndex) {
      stringBreakpoints.push([value.substring(pointer, startIndex), false]);
    }
    stringBreakpoints.push([
      value.substring(startIndex + openTag.length, endIndex),
      true,
    ]);
    pointer = endIndex + closeTag.length;
  }

  const styledComponents = stringBreakpoints.map(
    ([substring, highlight], ix) => {
      if (options?.sentenceCase && ix === 0)
        substring = getSentenceCase(substring);
      return (
        <span
          key={uuid()}
          className={highlight ? highlightClassName : className}
        >
          {substring}
        </span>
      );
    }
  );
  return <>{styledComponents}</>;
}

const offeringMatchLevelPriority: Record<MatchLevelEnum, number> = {
  [MatchLevelEnum.FULL]: 0,
  [MatchLevelEnum.PARTIAL]: 1,
  [MatchLevelEnum.SEMANTIC]: 1,
  [MatchLevelEnum.NONE]: 2,
};

function offeringComparator(offering1: MatchResult, offering2: MatchResult) {
  if (offering1.matchLevel === offering2.matchLevel) {
    return (offering2.semanticScore || 0) - (offering1.semanticScore || 0);
  }

  return (
    offeringMatchLevelPriority[offering1.matchLevel] -
    offeringMatchLevelPriority[offering2.matchLevel]
  );
}

const MAX_OFFERINGS = 5;

export function useResultSupplierOfferings(
  hit: SupplierContractHit | SupplierHit | RelevantContractSupplier
) {
  return useMemo(() => {
    return hit.supplierOfferings
      .sort(offeringComparator)
      .slice(0, MAX_OFFERINGS);
  }, [hit]);
}
