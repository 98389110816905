import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";

import { PurchaseIntentFormVariant } from "../../components/PurchaseIntentForm";
import { ApiService } from "../../generated";
import type { ListedProject } from "../../generated/models/ListedProject";
import useDeleteProjectItem from "../../hooks/useDeleteProjectItem";
import useLoginWall from "../../hooks/useLoginWall";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import useSavedProjectId from "../../hooks/useSavedProjectId";
import {
  projectConstantsAtom,
  projectContextState,
} from "../../jotai/projects";
import { requireIntakeFormEnabledState } from "../../jotai/user";
import { Badge, Button, DropdownMenu } from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import type { TypographyColor } from "../../library/Typography/types";
import { useShowNewProjectModal } from "../../modals/ProjectModals/NewProjectModal";
import { goToURL } from "../../utils";
import { isFeatureEnabled } from "../../utils/features";
import { handleError } from "../../utils/generatedApi";
import { trackOpenSaveToProjectDropdown } from "../../utils/tracking";
import { usePurchaseIntentModal } from "../SearchModal/PurchaseIntentModal";
import { useSaveToProjectButtonProps } from "../saveToProjectUtils";
import type { SaveToProjectButtonProps } from "./types";

export default function SaveToAnyProjectButton({
  itemId,
  itemType,
  supplierId,
  additionalItems = [],
  buttonClassName,
  source,
  className,
  dataTestId = "save-to-project",
  showOutreachModal,
}: SaveToProjectButtonProps) {
  const { name } = useAtomValue(projectConstantsAtom);
  const projectContext = useAtomValue(projectContextState);
  const [userProjects, setUserProjects] = useState<ListedProject[]>([]);
  const showIntentModal = usePurchaseIntentModal();
  const showNewProjectModal = useShowNewProjectModal();
  const requireLogin = useLoginWall();
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);
  const showNewProjectButton =
    !isFeatureEnabled("projectBrowsing") || !requireIntakeForm;
  const saveButtonProps = useSaveToProjectButtonProps();
  const savedProjectId = useSavedProjectId(supplierId);
  const saveProjectItem = useSaveProjectItem(source);
  const deleteProjectItem = useDeleteProjectItem();

  useEffect(() => {
    // When there's an active project, don't list all projects. In practice,
    // this should not happen and we should show the SaveToCurrentProjectButton
    // instead.
    if (projectContext?.id) return;

    (async () => {
      try {
        const projects = await ApiService.apiV1ProjectsList(true);
        setUserProjects(projects);
      } catch (e) {
        handleError(e);
      }
    })();
  }, [projectContext]);

  const onClick = useCallback(async () => {
    if (savedProjectId) {
      deleteProjectItem(itemId, supplierId, savedProjectId);
      return;
    }

    trackOpenSaveToProjectDropdown({ source });
    requireLogin({
      onComplete: () => {},
      triggerType: saveButtonProps[itemType].triggerType,
      triggerId: itemId,
    });
  }, [
    itemId,
    itemType,
    savedProjectId,
    requireLogin,
    source,
    saveButtonProps,
    deleteProjectItem,
    supplierId,
  ]);

  const items: MenuItemProps[] = userProjects.map(
    ({ id, name, selectedOptionId }) => {
      return {
        key: id,
        label: name,
        className: "analytics-save-project-dropdown-project-item",
        onClick: () => {
          const payload = {
            projectId: id,
            itemId,
            itemType,
            purchaseOptionId: selectedOptionId,
          };
          if (showOutreachModal) {
            showOutreachModal({
              onClickCta: () => saveProjectItem(payload),
            });
          } else {
            saveProjectItem(payload);
          }
        },
      };
    }
  );

  if (showNewProjectButton) {
    items.push({
      label: `+ New ${name}`,
      className:
        "analytics-save-project-dropdown-add-project font-semibold sm:font-semibold",
      onClick: () => {
        if (requireIntakeForm) {
          goToURL("/intake");
          return;
        }

        if (isFeatureEnabled("projectBrowsing")) {
          showIntentModal({
            variant: PurchaseIntentFormVariant.CREATE,
            onSave: async (purchaseIntent) => {
              if (!purchaseIntent.projectId) return;
              saveProjectItem({
                projectId: purchaseIntent.projectId,
                itemId,
                itemType,
              });
            },
          });
          return;
        }

        showNewProjectModal({
          source,
          onCreate: (projectId) =>
            saveProjectItem({ projectId, itemId, itemType }),
          itemId,
          itemType,
          showOutreachModal,
        });
      },
      color: "brand.bold.enabled" as TypographyColor,
    });
  }

  items.push(...additionalItems);

  return (
    <div
      className={clsx("sm:relative w-fit", className)}
      data-testid={dataTestId}
    >
      <DropdownMenu
        items={items}
        label={showNewProjectButton ? `Save to ${name}` : ""}
        responsive
        buttonProps={{
          theme: Button.themes.SECONDARY_DARK,
          size: Button.sizes.SMALL,
          className: clsx(
            "gap-2 h-auto analytics-save-contract",
            buttonClassName
          ),
          onClick,
        }}
      >
        <Badge
          Icon={savedProjectId ? BookmarkIcon : BookmarkBorderOutlinedIcon}
        >
          {savedProjectId ? (
            saveButtonProps[itemType].savedCopy
          ) : (
            <>
              {saveButtonProps[itemType].saveCtaCopy}
              <ExpandMoreRoundedIcon className="w-5 h-5 ml-1" />
            </>
          )}
        </Badge>
      </DropdownMenu>
    </div>
  );
}
