import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import useDeleteProjectItem from "../../hooks/useDeleteProjectItem";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import useSavedProjectId from "../../hooks/useSavedProjectId";
import { projectContextState } from "../../jotai/projects";
import { Badge, Button, ButtonThemes } from "../../library";
import { useSaveToProjectButtonProps } from "../saveToProjectUtils";
import type { SaveToProjectButtonProps } from "./types";

export default function SaveToCurrentProjectButton({
  itemId,
  itemType,
  supplierId,
  source,
  className,
  buttonClassName,
  dataTestId = "save-to-current-project",
  showOutreachModal,
}: SaveToProjectButtonProps) {
  const projectId = useSavedProjectId(supplierId);
  const projectContext = useAtomValue(projectContextState);
  const saveButtonProps = useSaveToProjectButtonProps();
  const saveProjectItem = useSaveProjectItem(source);
  const deleteProjectItem = useDeleteProjectItem();

  const onClick = async () => {
    if (projectId) {
      deleteProjectItem(itemId, supplierId, projectId);
      return;
    }

    if (!projectContext) return;

    const payload = {
      projectId: projectContext.id,
      itemId,
      itemType,
      purchaseOptionId: projectContext?.purchaseRequest?.selectedOption?.id,
    };

    if (showOutreachModal) {
      showOutreachModal({
        onClickCta: () => saveProjectItem(payload),
      });
    } else {
      saveProjectItem(payload);
    }
  };

  if (
    projectContext?.purchaseRequest &&
    !projectContext.purchaseRequest.selectedOption
  ) {
    return;
  }

  return (
    <div className={clsx("sm:relative w-fit", className)}>
      <Button
        dataTestId={dataTestId}
        size={Button.sizes.SMALL}
        className={clsx("analytics-save-contract-to-project", buttonClassName)}
        theme={ButtonThemes.SECONDARY_DARK}
        onClick={onClick}
      >
        <Badge Icon={projectId ? BookmarkIcon : BookmarkBorderOutlinedIcon}>
          {projectId
            ? saveButtonProps[itemType].savedCopy
            : saveButtonProps[itemType].saveCtaCopy}
        </Badge>
      </Button>
    </div>
  );
}
