import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { userDetailsState } from "../../jotai/user";
import { Avatar, AvatarSizes, AvatarVariant } from "../../library";
import type { BgColor } from "../../utils/colors";

import type { ProjectCollaborator } from "../../generated";
import { withTooltip } from "../../library/Tooltip";

const NUM_AVATARS_SHOWN = 3;

const SELF_AVATAR_COLOR = "brand.subtler.hovered";

const AVATAR_COLORS: BgColor[] = [
  "brand.subtler.enabled",
  "accent.leaf.enabled",
  "accent.persimmon.enabled",
];

const AVATAR_STYLE =
  "relative w-9 h-9 font-semibold uppercase text-cp-meta-sm -ml-2 last:ml-0";

function getCollaboratorInitials(
  email: string,
  firstName: string,
  lastName: string
) {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }
  return email.charAt(0);
}

const AvatarWithTooltip = withTooltip(Avatar, {
  placement: "top",
  className: "!whitespace-nowrap",
});

export function SelfAvatar({ showTooltip = true }: { showTooltip?: boolean }) {
  const { firstName, lastName, email } = useAtomValue(userDetailsState);

  if (!(firstName && lastName && email)) return <></>;

  const tooltip = firstName && lastName ? `${firstName} ${lastName}` : email;
  return (
    <AvatarWithTooltip
      className={AVATAR_STYLE}
      bgColor={SELF_AVATAR_COLOR}
      monogram={getCollaboratorInitials(email, firstName, lastName)}
      size={AvatarSizes.MEDIUM}
      textColor="brand.bold"
      typographyClassName="font-semibold"
      variant={AvatarVariant.CIRCLE}
      tooltip={showTooltip ? tooltip : undefined}
    />
  );
}

export default function CollaboratorAvatars({
  collaborators,
  className,
}: {
  collaborators: ProjectCollaborator[];
  className?: string;
}) {
  const { email } = useAtomValue(userDetailsState);
  const otherCollaborators = useMemo(() => {
    const filteredCollaborators = collaborators.filter(
      (collaborator) => collaborator.userEmail !== email
    );
    return {
      rendered: filteredCollaborators.slice(0, NUM_AVATARS_SHOWN - 1),
      rest: filteredCollaborators.slice(NUM_AVATARS_SHOWN - 1),
    };
  }, [collaborators, email]);
  const showSelfAsCollaborator = collaborators.some(
    (collaborator) => collaborator.userEmail === email
  );

  return (
    <div
      className={clsx("inline-flex flex-row-reverse empty:hidden", className)}
    >
      {otherCollaborators.rest.length > 0 && (
        <AvatarWithTooltip
          className={AVATAR_STYLE}
          bgColor="accent.neutral.enabled"
          monogram={`+${collaborators.length - NUM_AVATARS_SHOWN}`}
          size={AvatarSizes.MEDIUM}
          textColor="brand.bold"
          typographyClassName="font-semibold"
          variant={AvatarVariant.CIRCLE}
          tooltip={otherCollaborators.rest
            .map(({ userEmail }) => userEmail)
            .join("\n")}
        />
      )}
      {otherCollaborators.rendered.length > 0 && (
        <>
          {otherCollaborators.rendered.map((collaborator, i) => (
            <AvatarWithTooltip
              key={collaborator.userEmail}
              className={AVATAR_STYLE}
              bgColor={AVATAR_COLORS[i]}
              monogram={getCollaboratorInitials(
                collaborator.userEmail,
                collaborator.firstName,
                collaborator.lastName
              )}
              size={AvatarSizes.MEDIUM}
              textColor="brand.bold"
              typographyClassName="font-semibold"
              variant={AvatarVariant.CIRCLE}
              tooltip={
                collaborator.firstName && collaborator.lastName
                  ? `${collaborator.firstName} ${collaborator.lastName}`
                  : collaborator.userEmail
              }
            />
          ))}
          {showSelfAsCollaborator && <SelfAvatar />}
        </>
      )}
    </div>
  );
}
