import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowBack from "@mui/icons-material/ArrowBack";
import clsx from "clsx";
import { useMemo } from "react";

import { captureMessage } from "@sentry/browser";
import { useAtomValue } from "jotai";
import { getProjectDetailURL } from "../../components/Project/utils";
import { PurchaseIntentFormVariant } from "../../components/PurchaseIntentForm";
import useProjectId from "../../hooks/useProjectId";
import { requireIntakeFormEnabledState } from "../../jotai/user";
import { Button, ButtonSizes, ButtonThemes, Link } from "../../library";
import type { LinkProps } from "../../library/Link";
import { getParam, goToURL } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { usePurchaseIntentModal } from "../SearchModal/PurchaseIntentModal";

export default function MinimalPageHeader() {
  const projectId = useProjectId();
  const showPurchaseIntentModal = usePurchaseIntentModal();
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);

  let Icon = undefined;
  if (projectId) Icon = ArrowBack;
  else Icon = AddRoundedIcon;

  let text = "Return to search results";
  if (requireIntakeForm) text = "Start purchase request";
  else if (!projectId) text = "New request";

  const linkProps = useMemo(() => {
    const linkProps: LinkProps = {
      underline: false,
      newWindow: false,
    };
    if (projectId) linkProps.href = getProjectDetailURL(projectId);
    else if (requireIntakeForm) linkProps.href = "/intake";
    else
      linkProps.onClick = () =>
        showPurchaseIntentModal({
          query: getParam("query", ""),
          variant: PurchaseIntentFormVariant.SEARCH,
          onSave: ({ projectId }) => {
            if (!projectId) {
              captureMessage(
                "No project ID returned from purchase intent creation"
              );
              return;
            }
            goToURL(getProjectDetailURL(projectId), {}, false);
          },
        });
    return linkProps;
  }, [projectId, showPurchaseIntentModal, requireIntakeForm]);

  return (
    <header
      className={clsx(
        "sticky top-0 z-3 px-6 pb-1 h-12 flex items-end",
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <Button
        as={Link}
        dataTestId="back-to-search"
        size={ButtonSizes.SMALL}
        theme={ButtonThemes.TERTIARY_DARK}
        badgeProps={{
          Icon,
        }}
        linkProps={linkProps}
      >
        {text}
      </Button>
    </header>
  );
}
