import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useEffect, useState } from "react";
import HeartContract from "../../../img/icons/contracts-heart.svg";
import type { Project } from "../../generated";
import { ApiService } from "../../generated/services/ApiService";
import { Button, Link, PageSection, Typography } from "../../library";
import { getParam, getParams, goToURL } from "../../utils";
import { SUPPORT_EMAIL_ADDRESS } from "../../utils/constants";
import { generateHrefForEmail } from "../../utils/format";
import { handleError } from "../../utils/generatedApi";
import ProjectCard from "../Project/ProjectCard";

export default function QuoteRequestSuccessPage() {
  const params = getParams();
  const newProjectId = params.newProjectId?.toString();

  const [newProject, setNewProject] = useState<Project | null>(null);
  useEffect(() => {
    (async () => {
      if (!newProjectId) return;
      try {
        const response = await ApiService.apiV1ProjectsRetrieve(newProjectId);
        setNewProject(response);
      } catch (error) {
        handleError(error);
      }
    })();
  }, [newProjectId]);

  const isRequestingLocalServices = getParam("local") === "true";
  const title = isRequestingLocalServices
    ? "Thanks for submitting your request"
    : "Quote request sent!";

  const fromSearch = !!getParam("query");

  const body = isRequestingLocalServices ? (
    <Typography>
      To ensure we can provide the best support possible, we are currently
      prioritizing requests for goods and services available nationwide. If
      you’d still like to discuss your needs or if you have more questions,
      contact us at{" "}
      <Link
        underline={false}
        emphasis={false}
        href={generateHrefForEmail(SUPPORT_EMAIL_ADDRESS)}
      >
        {SUPPORT_EMAIL_ADDRESS}
      </Link>
      .
    </Typography>
  ) : (
    <div className="flex flex-col gap-4">
      <Typography emphasis>What happens next?</Typography>
      <Typography>
        Our team will email you with supplier options for your need in 1-2 days.
        In the meantime, we've created a project for you to get a head start on
        your research. Start saving supplier and contract options there, and our
        team will add more options.
      </Typography>
      {newProject && (
        <ProjectCard
          {...{
            ...newProject,
            selectedOptionId: null,
            purchaseIntentTimeline: null,
          }}
        />
      )}
    </div>
  );
  return (
    <PageSection className="pt-12 pb-48">
      <div className="flex items-center mb-12">
        <Typography
          variant="display"
          size="sm"
          color="brand.default.secondary.enabled"
        >
          {title}
        </Typography>
        <img src={HeartContract} className="w-fit h-[6rem]" />
      </div>
      <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col-reverse flex-col gap-12">
        <div className="lg:col-span-8 flex flex-col gap-10">
          {body}
          {fromSearch ? (
            <Button
              onClick={() => {
                goToURL(
                  `/contract-search/${window.location.search}`,
                  {},
                  false
                );
              }}
              className="flex-none w-fit"
              theme={Button.themes.SECONDARY_DARK}
              badgeProps={{ Icon: ArrowBackRoundedIcon }}
            >
              Take me back to search results
            </Button>
          ) : (
            <Button
              onClick={() => {
                goToURL(`/${window.location.search}`, {}, false);
              }}
              className="flex-none w-fit"
              theme={Button.themes.SECONDARY_DARK}
              badgeProps={{ Icon: ArrowBackRoundedIcon }}
            >
              Home
            </Button>
          )}
        </div>
      </div>
    </PageSection>
  );
}
