import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService } from "../generated";
import {
  projectContextState,
  projectPurchaseOptionSuppliersAtom,
  savedProjectState,
} from "../jotai/projects";
import { handleError } from "../utils/generatedApi";
import useRefreshSavedSuppliers from "./useRefreshSavedSuppliers";

export default function useDeleteProjectItem() {
  const refreshSavedSuppliers = useRefreshSavedSuppliers();

  const deleteProjectItem = useCallback(
    async (
      get: Getter,
      set: Setter,
      itemId: string,
      supplierId: number,
      projectId: string
    ) => {
      const projectContext = get(projectContextState);
      if (projectContext?.id) {
        try {
          await ApiService.apiV1ProjectsSuppliersDestroy(
            projectId,
            supplierId.toString()
          );
        } catch (e) {
          handleError(e);
          return;
        }
        if (projectContext?.purchaseRequest?.selectedOption) {
          set(projectPurchaseOptionSuppliersAtom);
        } else {
          refreshSavedSuppliers();
        }
        return;
      }
      try {
        await ApiService.apiV1ProjectsItemsDestroy(itemId, projectId);
      } catch (e) {
        handleError(e);
        return;
      }
      set(savedProjectState, null);
    },
    [refreshSavedSuppliers]
  );

  return useAtomCallback(deleteProjectItem);
}
