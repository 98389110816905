import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Field, Form, Formik } from "formik";
import type {
  BaseSupplier,
  CreatePurchaseIntentRequest,
} from "../../generated";
import { Button } from "../../library";
import usePurchaseIntentFields, {
  VALIDATION_SCHEMA,
} from "./usePurchaseIntentFields";

export type PurchaseIntentFormValues = Omit<
  CreatePurchaseIntentRequest,
  "suppliers"
> & {
  suppliers: BaseSupplier[];
};

function PurchaseIntentFields() {
  const fields = usePurchaseIntentFields();

  return (
    <>
      {fields.map((props) => (
        <Field key={props.name} editable {...props} />
      ))}
    </>
  );
}

// This shape is validated at runtime by fields and the validation schema.
export const PURCHASE_INTENT_DEFAULT_VALUES = {
  query: "",
  timeline: undefined,
  supplierDecision: undefined,
  suppliers: [],
} as unknown as PurchaseIntentFormValues;

export enum PurchaseIntentFormVariant {
  SEARCH = "search",
  CREATE = "create",
  EDIT = "edit",
}

export function PurchaseIntentForm({
  onSubmit,
  initialValues,
  variant,
}: {
  onSubmit: (values: CreatePurchaseIntentRequest) => Promise<void>;
  initialValues: PurchaseIntentFormValues;
  variant: PurchaseIntentFormVariant;
}) {
  let text = "Search";
  if (variant === PurchaseIntentFormVariant.CREATE) {
    text = "Save request";
  } else if (variant === PurchaseIntentFormVariant.EDIT) {
    text = "Update request";
  }
  const Icon =
    variant === PurchaseIntentFormVariant.SEARCH
      ? SearchRoundedIcon
      : undefined;

  return (
    <Formik
      onSubmit={({ suppliers, ...rest }) =>
        onSubmit({
          ...rest,
          suppliers: suppliers.map(({ id }) => id),
        })
      }
      initialValues={initialValues}
      validateOnChange
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
    >
      <Form>
        <PurchaseIntentFields />
        <Button
          size={Button.sizes.LARGE}
          className="w-fit mt-6"
          type="submit"
          dataTestId="purchase-submit"
          badgeProps={{
            Icon,
          }}
        >
          {text}
        </Button>
      </Form>
    </Formik>
  );
}
