import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { DifferenceOutlined, SchoolOutlined } from "@mui/icons-material";
import pluralize from "pluralize";
import pavilionLogo from "../../../img/logos/pavilion-logo.svg";
import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import { pageTypeState } from "../../jotai/page";
import {
  numProjectsAtom,
  projectConstantsAtom,
  projectContextState,
  sidebarProjectsAtom,
} from "../../jotai/projects";
import {
  buyerProfileState,
  logoutCallback,
  profileTypeState,
  requireIntakeFormEnabledState,
} from "../../jotai/user";
import { Link } from "../../library";
import { useShowInviteTeamModal } from "../../modals/InviteTeamModal";
import { COMPANY_NAME } from "../../utils/constants";
import {
  INVITE_TEAM_SOURCE,
  IntakeFormOpenSource,
  PageType,
  ProfileType,
  SidebarLinkType,
} from "../../utils/enums";
import {
  trackIntakeFormOpen,
  trackSidebarLinkClick,
} from "../../utils/tracking";
import NewRequestButton from "./NewRequestButton";
import SidebarHelpCard from "./SidebarHelpCard";
import SidebarLink from "./SidebarLink";

function SidebarSection({
  className,
  children,
}: { className?: string; children: React.ReactNode }) {
  return (
    <div
      className={clsx("px-2 py-4 flex flex-col gap-4 items-start", className)}
    >
      {children}
    </div>
  );
}

function SidebarLinkContainer({
  className,
  children,
}: { className?: string; children: React.ReactNode }) {
  return <div className={clsx("w-full grid gap-1", className)}>{children}</div>;
}

export default function Sidebar({ className }: { className?: string }) {
  const showInviteTeamModal = useShowInviteTeamModal();
  const supplierEditUrl = useSupplierEditUrl();
  const { name } = useAtomValue(projectConstantsAtom);
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const profileType = useAtomValue(profileTypeState);
  const pageType = useAtomValue(pageTypeState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);
  const projectContext = useAtomValue(projectContextState);
  const numProjects = useAtomValue(numProjectsAtom);
  const projects = useAtomValue(sidebarProjectsAtom);
  const remainingProjects = numProjects - projects.length;

  const logOut = useAtomCallback(logoutCallback());

  return (
    <nav
      className={clsx(
        "box-content max-h-screen min-h-screen w-[240px] flex-none flex-col divide-y overflow-y-auto border-r bg-cp-neutral-palette-50 z-3",
        className
      )}
      data-testid="sidebar-nav"
    >
      <SidebarSection>
        <Link
          className="analytics-logo-link mt-2 mx-2"
          href="/welcome"
          target="_parent"
          newWindow={false}
          onClick={() =>
            trackSidebarLinkClick({ sidebarLinkType: SidebarLinkType.HOME })
          }
          dataTestId="sidebar-nav-logo-link"
        >
          <img
            className="h-7"
            src={pavilionLogo}
            alt={`${COMPANY_NAME} logo`}
          />
        </Link>
        <SidebarLinkContainer>
          <SidebarLink
            sidebarLinkType={SidebarLinkType.HOME}
            active={pageType === PageType.PROFILE}
            Icon={HomeOutlinedIcon}
            activeIcon={HomeRoundedIcon}
            label="Home"
            href="/welcome"
            dataTestId="sidebar-nav-home-link"
          />
          {!requireIntakeForm && governmentAgency?.id && (
            <SidebarLink
              sidebarLinkType={SidebarLinkType.ENTITY_CONTRACTS}
              active={pageType === PageType.ENTITY_CONTRACTS}
              Icon={AccountBalanceOutlinedIcon}
              activeIcon={AccountBalanceRoundedIcon}
              label="My entity contracts"
              href="/entity-contracts"
              dataTestId="sidebar-nav-entity-contracts-link"
            />
          )}
        </SidebarLinkContainer>
      </SidebarSection>
      {governmentAgency?.id && (
        <SidebarSection className="flex-grow">
          <NewRequestButton
            className="w-fit"
            dataTestId="sidebar-nav-new-request-button"
            onClick={() => {
              trackIntakeFormOpen({ source: IntakeFormOpenSource.SIDEBAR });
              trackSidebarLinkClick({
                sidebarLinkType: SidebarLinkType.NEW_REQUEST,
              });
            }}
          />
          <SidebarLinkContainer>
            <SidebarLink
              sidebarLinkType={SidebarLinkType.REQUEST_HEADER}
              active={pageType === PageType.PROJECT}
              Icon={FolderOutlinedIcon}
              activeIcon={FolderRoundedIcon}
              label={`All ${pluralize(name)}`}
              href="/requests"
              dataTestId="sidebar-nav-requests-link"
            />
            {projects.map((project) => (
              <SidebarLink
                className="ml-2"
                key={project.id}
                sidebarLinkType={SidebarLinkType.REQUEST}
                label={
                  projectContext?.id === project.id && projectContext.name
                    ? projectContext.name
                    : project.name
                }
                href={`/requests/${project.id}`}
                Icon={ArticleOutlinedIcon}
                activeIcon={ArticleRoundedIcon}
                active={projectContext?.id === project.id}
                dataTestId={`sidebar-nav-request-link-${project.id}`}
              />
            ))}
            {remainingProjects > 0 && (
              <SidebarLink
                className="ml-2"
                sidebarLinkType={SidebarLinkType.MORE_REQUESTS}
                label={`+ ${remainingProjects} more`}
                href="/requests"
                active={false}
              />
            )}
          </SidebarLinkContainer>
        </SidebarSection>
      )}
      {profileType === ProfileType.SUPPLIER && (
        <SidebarSection>
          <SidebarLinkContainer>
            <SidebarLink
              sidebarLinkType={SidebarLinkType.SUPPLIER_CONTRACTS}
              active={
                pageType === PageType.SUPPLIER_EDIT &&
                window.location.hash === ""
              }
              Icon={ArticleOutlinedIcon}
              activeIcon={ArticleRoundedIcon}
              label="Business profile"
              href={supplierEditUrl || ""}
              dataTestId="sidebar-nav-business-profile-link"
            />
            <SidebarLink
              sidebarLinkType={SidebarLinkType.SUPPLIER_EDIT}
              active={
                pageType === PageType.SUPPLIER_EDIT &&
                window.location.hash === "#contracts"
              }
              Icon={BusinessCenterOutlinedIcon}
              activeIcon={BusinessCenterRoundedIcon}
              label="Contracts"
              href={supplierEditUrl?.replace("edit", "manage-contracts") || ""}
            />
          </SidebarLinkContainer>
        </SidebarSection>
      )}
      <SidebarSection>
        {governmentAgency?.id && <SidebarHelpCard />}
        <SidebarLinkContainer>
          {governmentAgency?.id && (
            <>
              <SidebarLink
                sidebarLinkType={SidebarLinkType.INVITE_TEAM}
                active={false}
                Icon={GroupAddOutlinedIcon}
                label="Invite your team"
                onClick={() =>
                  showInviteTeamModal({
                    inviteSource: INVITE_TEAM_SOURCE.SIDEBAR,
                  })
                }
                dataTestId="sidebar-invite-team-link"
              />
              <SidebarLink
                sidebarLinkType={SidebarLinkType.HOW_TO_USE_PAVILION}
                active={false}
                Icon={SchoolOutlined}
                label="How to use Pavilion"
                href="/about/resources/pavilion-product-walkthrough"
                dataTestId="sidebar-how-to-use-pavilion-link"
                newWindow
              />
              <SidebarLink
                sidebarLinkType={SidebarLinkType.COST_CALCULATOR}
                active={false}
                Icon={DifferenceOutlined}
                label="Compare procurement cost"
                href="/calculator"
                dataTestId="sidebar-calculator-link"
                newWindow
              />
            </>
          )}
          <SidebarLink
            sidebarLinkType={SidebarLinkType.PREFERENCES}
            active={pageType === PageType.ACCOUNT}
            Icon={SettingsOutlinedIcon}
            activeIcon={SettingsRoundedIcon}
            label="Account preferences"
            href="/profile"
            dataTestId="sidebar-nav-preferences-link"
          />
          <SidebarLink
            sidebarLinkType={SidebarLinkType.LOGOUT}
            active={false}
            Icon={LogoutOutlinedIcon}
            label="Log out"
            onClick={logOut}
            dataTestId="sidebar-nav-logout-link"
          />
        </SidebarLinkContainer>
      </SidebarSection>
    </nav>
  );
}
