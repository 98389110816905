import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";

import {
  projectContextState,
  projectPurchaseOptionSuppliersAtom,
  savedProjectState,
  savedSuppliersAtom,
} from "../jotai/projects";
import useRefreshSavedSuppliers from "./useRefreshSavedSuppliers";

export default function useSavedProjectId(supplierId: number) {
  const refreshSavedSuppliers = useRefreshSavedSuppliers();
  const savedSuppliers = useAtomValue(savedSuppliersAtom);
  const projectContext = useAtomValue(projectContextState);
  const savedProject = useAtomValue(savedProjectState);
  const purchaseOptionSavedSuppliers = useAtomValue(
    projectPurchaseOptionSuppliersAtom
  );

  useEffect(() => {
    refreshSavedSuppliers();
  }, [refreshSavedSuppliers]);

  // When outside the project context, use the backend provided saved project.
  // When there's a purchase request, use saved suppliers from the purchase option.
  // Otherwise, use saved suppliers from the project context.
  return useMemo(() => {
    if (!projectContext?.id) return savedProject?.id;
    if (projectContext.purchaseRequest) {
      return purchaseOptionSavedSuppliers.find(
        ({ supplier }) => supplier.id === supplierId
      )
        ? projectContext.id
        : null;
    }

    return savedSuppliers.find(({ supplier }) => supplier.id === supplierId)
      ? projectContext.id
      : null;
  }, [
    projectContext,
    savedProject,
    savedSuppliers,
    supplierId,
    purchaseOptionSavedSuppliers,
  ]);
}
