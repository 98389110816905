import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useMemo } from "react";
import {
  entityProjectsLoadable,
  projectConstantsAtom,
  projectsLoadable,
} from "../../jotai/projects";
import { PageSection, Typography } from "../../library";
import { useShowSidebar } from "../../shared/Sidebar/useShowSidebar";
import Tabs from "../../shared/Tabs";
import { TabLinkSize } from "../../shared/Tabs/TabLink";
import { bgColorClass } from "../../utils/colors";
import ProjectsTable from "./ProjectsTable";
import { useShowProjectsTabs } from "./utils";

function useProjectsTabs() {
  const projectConstants = useAtomValue(projectConstantsAtom);
  return useMemo(() => {
    const pluralizedName = pluralize(projectConstants.name);
    return [
      {
        name: `Your ${pluralizedName}`,
        Component: YourProjectsTable,
        hash: "",
        dataTestId: "your-requests-tab",
      },
      {
        name: `All entity ${pluralizedName}`,
        Component: EntityProjectsTable,
        hash: "entity",
        dataTestId: "entity-requests-tab",
      },
    ];
  }, [projectConstants]);
}

function YourProjectsTable() {
  const projectsList = useAtomValue(projectsLoadable);
  const yourProjects =
    projectsList.state === "hasData" ? projectsList.data : [];

  return (
    <ProjectsTable
      projects={yourProjects}
      isLoading={projectsList.state === "loading"}
    />
  );
}

function EntityProjectsTable() {
  const entityProjectsList = useAtomValue(entityProjectsLoadable);
  const entityProjects =
    entityProjectsList.state === "hasData" ? entityProjectsList.data : [];

  return (
    <ProjectsTable
      projects={entityProjects}
      isLoading={entityProjectsList.state === "loading"}
    />
  );
}

export default function AllProjects() {
  const showSidebar = useShowSidebar();
  const showTabs = useShowProjectsTabs();
  const tabs = useProjectsTabs();
  const { name } = useAtomValue(projectConstantsAtom);

  return (
    <PageSection>
      <div
        className={clsx("grid gap-10", {
          "pt-8": showSidebar,
        })}
      >
        {showTabs ? (
          <Tabs
            size={TabLinkSize.MEDIUM}
            className="pb-24"
            tabs={tabs}
            tabListClassName={clsx(
              "px-2 sticky z-2 items-end",
              {
                "h-12 top-0": showSidebar,
                "h-8 top-20": !showSidebar,
              },
              bgColorClass.neutral.subtlest.enabled
            )}
          />
        ) : (
          <>
            <Typography
              size="sm"
              variant="display"
              color="brand.boldest.enabled"
              className="!font-normal"
            >
              Your {pluralize(name)}
            </Typography>
            <YourProjectsTable />
          </>
        )}
      </div>
    </PageSection>
  );
}
