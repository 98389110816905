import type { ListedProject } from "../../generated";
import { Table } from "../../library";
import { goToURL } from "../../utils";
import useProjectsTableColumn from "./useProjectsTableColumns";
import { getProjectDetailURL, useShowProjectsTabs } from "./utils";

export default function ProjectsTable({
  projects,
  isLoading,
}: { projects: ListedProject[]; isLoading: boolean }) {
  const columns = useProjectsTableColumn();
  const showProjectsTabs = useShowProjectsTabs();
  return (
    <Table
      isLoading={isLoading}
      data={projects}
      columns={columns}
      getId={({ id }) => id}
      onRowClick={({ id }) => goToURL(getProjectDetailURL(id))}
      headerClassName={showProjectsTabs ? "top-12" : "top-0"}
    />
  );
}
