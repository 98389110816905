import { useAtomValue } from "jotai";
import { useMemo } from "react";
import {
  PURCHASE_INTENT_DEFAULT_VALUES,
  PurchaseIntentForm,
  type PurchaseIntentFormVariant,
} from "../../components/PurchaseIntentForm";
import {
  ApiService,
  type CreatePurchaseIntentRequest,
  type ProjectPurchaseIntent,
  type PurchaseIntent,
} from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { preferredSuppliersLoadable } from "../../jotai/projects";
import { modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import Modal, { modalSizes } from "../Modal/Modal";

interface PurchaseIntentModalProps {
  hideModal: () => void;
  onSave: (purchaseIntent: PurchaseIntent) => void;
  purchaseIntent?: ProjectPurchaseIntent;
  variant: PurchaseIntentFormVariant;
  isBlocking?: boolean;
  query?: string;
}

export function usePurchaseIntentModal() {
  const show = useShowModal(modals.PURCHASE_INTENT);
  return (props: Omit<PurchaseIntentModalProps, "hideModal">) => show(props);
}

export default function PurchaseIntentModal({
  hideModal,
  onSave,
  purchaseIntent,
  variant,
  isBlocking,
  query,
}: PurchaseIntentModalProps) {
  const preferredSuppliers = useAtomValue(preferredSuppliersLoadable);

  const initialValues = useMemo(() => {
    if (purchaseIntent) {
      return {
        ...purchaseIntent,
        suppliers:
          preferredSuppliers.state === "hasData" ? preferredSuppliers.data : [],
      };
    }
    return {
      ...PURCHASE_INTENT_DEFAULT_VALUES,
      query: query || "",
    };
  }, [purchaseIntent, preferredSuppliers, query]);

  async function onCreate(values: CreatePurchaseIntentRequest) {
    const response = await ApiService.apiV1PurchaseIntentsCreate(values);
    return response;
  }

  async function onUpdate(id: number, values: CreatePurchaseIntentRequest) {
    const response = await ApiService.apiV1PurchaseIntentsUpdate(id, values);
    return response;
  }

  async function onSubmit(values: CreatePurchaseIntentRequest) {
    try {
      const response = purchaseIntent
        ? await onUpdate(purchaseIntent.id, values)
        : await onCreate(values);
      onSave(response);
      hideModal();
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-intake-edit-modal"
      title={purchaseIntent ? "Edit request info" : "New purchase request"}
      modalSize={modalSizes.DEFAULT}
      isBlocking={isBlocking}
    >
      <PurchaseIntentForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        variant={variant}
      />
    </Modal>
  );
}
