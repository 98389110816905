import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { ApiService } from "../../generated";
import type { ListedProject } from "../../generated/models/ListedProject";
import { projectsAtom } from "../../jotai/projects";
import { intakeFormEnabledState } from "../../jotai/user";
import { ActionMenu, Badge } from "../../library";
import type { ActionMenuItem } from "../../library/Dropdown/ActionMenu";
import type { ColumnDefinition } from "../../library/Table/types";
import { useDeleteProjectConfirmModal } from "../../modals/ProjectModals/DeleteProjectConfirmModal";
import { useShowInviteModal } from "../../modals/ProjectModals/ProjectInviteModal";
import RadioPartialIcon from "../../shared/CustomIcons/RadioPartialIcon";
import { getSentenceCase, goToURL } from "../../utils";
import { iconColorClass } from "../../utils/colors";
import { formatDate } from "../../utils/date";
import { handleError } from "../../utils/generatedApi";
import { trackArchiveProject, trackDeleteProject } from "../../utils/tracking";
import {
  TIMELINE_DISPLAY,
  TIMELINE_VALUES,
} from "../PurchaseIntentForm/TimelineOptionsField";
import CollaboratorAvatars from "./CollaboratorAvatars";
import { getProjectDetailURL } from "./utils";

function nullComparator<T>(
  a: T | null,
  b: T | null,
  comparator: (a: T, b: T) => number
) {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return comparator(a, b);
}

function stringComparator(a: string, b: string) {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  if (lowerA < lowerB) return -1;
  if (lowerA > lowerB) return 1;
  return 0;
}

function getColumns({
  onComplete,
  onShare,
  onDelete,
  intakeFormEnabled,
}: {
  onComplete: (project: ListedProject) => void;
  onShare: (project: ListedProject) => void;
  onDelete: (project: ListedProject) => void;
  intakeFormEnabled: boolean;
}): ColumnDefinition<ListedProject>[] {
  return [
    {
      key: "name",
      label: "Name",
      customWidthClass: "max-w-1/3",
      isSortable: true,
      render: (v) => (
        <div className="line-clamp-2 overflow-hidden text-ellipsis">{v}</div>
      ),
      comparator: stringComparator,
    },
    {
      key: "archivedAt",
      label: "Status",
      isSortable: true,
      render: (v) =>
        v ? (
          <Badge
            Icon={CheckCircleIcon}
            iconClass={iconColorClass.accent.leaf.default}
            className="whitespace-nowrap"
          >
            Complete
          </Badge>
        ) : (
          <Badge Icon={RadioPartialIcon} className="whitespace-nowrap">
            In progress
          </Badge>
        ),
      comparator: (a, b) => nullComparator(a, b, () => 0),
    },
    intakeFormEnabled
      ? {
          key: "selectedOptionTitle",
          label: "Purchase method",
          isSortable: true,
          render: (v) => (v ? getSentenceCase(v) : ""),
          comparator: (a, b) => nullComparator(a, b, stringComparator),
        }
      : {
          key: "purchaseIntentTimeline",
          label: "Timeline",
          isSortable: true,
          render: (v) => (v ? TIMELINE_DISPLAY[v] : ""),
          comparator: (a, b) =>
            nullComparator(a, b, (a, b) => {
              const aVal = TIMELINE_VALUES[a];
              const bVal = TIMELINE_VALUES[b];
              return aVal - bVal;
            }),
        },
    {
      key: "numSuppliers",
      label: "Suppliers saved",
      isSortable: true,
      render: (v, { supplierDisplayNames }) => (
        <div>
          <div>{v}</div>
          <div
            className="line-clamp-2 overflow-hidden text-ellipsis empty:hidden"
            title={supplierDisplayNames.join("; ")}
          >
            {supplierDisplayNames.join("; ")}
          </div>
        </div>
      ),
    },
    {
      key: "collaborators",
      label: "Team members",
      isSortable: true,
      render: (v) => <CollaboratorAvatars collaborators={v} />,
      comparator: (a, b) => {
        const aVal = a.length;
        const bVal = b.length;
        return aVal - bVal;
      },
    },
    {
      key: "lastModifiedAt",
      label: "Last activity",
      isSortable: true,
      render: (v, p) => {
        const activeItems: ActionMenuItem[] = p.archivedAt
          ? []
          : [
              {
                text: "Open",
                onClick: () => {
                  goToURL(getProjectDetailURL(p.id), {}, false);
                },
                dataTestId: `${p.id}-open`,
              },
              {
                text: "Share",
                onClick: () => onShare(p),
                dataTestId: `${p.id}-share`,
              },
              {
                text: "Mark as complete",
                onClick: () => onComplete(p),
                dataTestId: `${p.id}-complete`,
              },
            ];
        return (
          <div className="flex justify-between gap-6">
            <div>{formatDate(v, "MM/dd/yyyy")}</div>
            <ActionMenu
              align="right"
              dataTestId={`${p.id}-menu`}
              items={[
                ...activeItems,
                {
                  text: "Delete",
                  onClick: () => onDelete(p),
                  color: "destructive.default.primary.enabled",
                  dataTestId: `${p.id}-delete`,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default function useProjectsTableColumn() {
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const refreshProjects = useSetAtom(projectsAtom);

  const showInvite = useShowInviteModal();
  const onShare = useCallback(
    ({ id }: ListedProject) => showInvite({ projectId: id }),
    [showInvite]
  );

  const onComplete = useCallback(
    async ({ id }: ListedProject) => {
      try {
        await ApiService.apiV1ProjectsArchiveCreate(id, {});
        trackArchiveProject({ projectId: id });
        refreshProjects();
      } catch (err) {
        handleError(err);
      }
    },
    [refreshProjects]
  );

  const showDelete = useDeleteProjectConfirmModal();
  const onDelete = useCallback(
    ({ id, name }: ListedProject) => {
      showDelete({
        projectName: name,
        onConfirm: async () => {
          try {
            await ApiService.apiV1ProjectsDestroy(id);
            trackDeleteProject({ projectId: id });
            refreshProjects();
          } catch (err) {
            handleError(err);
          }
        },
      });
    },
    [refreshProjects, showDelete]
  );

  return useMemo(
    () => getColumns({ onComplete, onShare, onDelete, intakeFormEnabled }),
    [onComplete, onShare, onDelete, intakeFormEnabled]
  );
}
