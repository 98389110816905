import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { captureMessage } from "@sentry/browser";
import { getProjectDetailURL } from "../../components/Project/utils";
import { PurchaseIntentFormVariant } from "../../components/PurchaseIntentForm";
import { intakeFormEnabledState } from "../../jotai/user";
import { Button, Link } from "../../library";
import { goToURL } from "../../utils";
import { getParam } from "../../utils";
import { getSearchPath } from "../../utils/format";
import { usePurchaseIntentModal } from "../SearchModal/PurchaseIntentModal";

export default function NewRequestButton({
  className,
  dataTestId,
  onClick,
}: { className?: string; dataTestId?: string; onClick?: () => void }) {
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const showPurchaseIntent = usePurchaseIntentModal();

  const props = useMemo(() => {
    if (intakeFormEnabled) {
      return {
        linkProps: {
          href: "/intake",
          underline: false,
          newWindow: false,
          onClick,
        },
        as: Link,
      };
    }

    return {
      onClick: () => {
        showPurchaseIntent({
          variant: PurchaseIntentFormVariant.SEARCH,
          query: getParam("query", ""),
          onSave: ({ query, projectId }) => {
            if (projectId) {
              goToURL(getProjectDetailURL(projectId), {}, false);
              return;
            }
            captureMessage(
              "No project ID returned from purchase intent creation"
            );
            goToURL(
              getSearchPath({
                query,
                queryZip: "",
                analyticsSearchSource: "purchase-intent-modal",
              }),
              {}
            );
          },
        });
        onClick?.();
      },
    };
  }, [intakeFormEnabled, onClick, showPurchaseIntent]);

  return (
    <Button
      theme={Button.themes.PRIMARY_DARK}
      size={Button.sizes.SMALL}
      className={className}
      dataTestId={dataTestId}
      badgeProps={{ Icon: AddRoundedIcon }}
      {...props}
    >
      New request
    </Button>
  );
}
